<template>
  <div>
    <!--    <van-search-->
    <!--      v-model="queryForm.QDDX_MC"-->
    <!--      shape="round"-->
    <!--      background="#2778F8"-->
    <!--      placeholder="请输入劝导对象"-->
    <!--      @search="onSearch"-->
    <!--      @cancel="onCancel"-->
    <!--    />-->

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="15"
        @load="onLoad"
      >
        <div>
          <div v-if="flag === true">
            <div
              v-for="(item, i) in list"
              :key="i"
              class="yun_newedition_resume"
            >
              <div class="yun_newedition_resumelist">
                <a @click="toInfo(item)">
                  <div class="yun_newedition_resume_wantjob">
                    <span class="yun_newedition_resume_wantjob_n">
                      标题：{{ item.Title }}
                    </span>
                  </div>
                  <div class="user_undergo_box">
                    <div class="user_undergo user_undergo_a">
                      投诉人姓名：{{ item.Name }}
                    </div>
                    <div class="user_undergo user_undergo_a">
                      投诉人联系方式：{{ item.Phone }}
                    </div>
                    <div class="user_undergo user_undergo_a">
                      最近操作时间：{{ item.Date_OP }}
                    </div>
                    <div class="user_undergo user_undergo_a">
                      状态：
                      <span>
                        {{ item.Type }}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无用户投诉记录，请重新查询" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
  import moment from 'moment'
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Toast } from 'vant'
  import { GetUserComplainList } from '@/api/workBench/userComplain/UserComplain'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    data() {
      return {
        list: [],
        // type: '',
        // value: '',
        flag: true,
        loading: false, //是否处于加载状态
        finished: false, //是否已加载完所有数据
        isFinished: false,
        pageCount: '', //总页数
        //date: new Date(),
        queryForm: {
          intPageIndex: 1,
          intPageSize: 10,
          User_ID: '',
          // QDDX_MC: '',
        },
        isLoading: false,
        // showPicker: false,
        // showStatus: false,
        // name: '',
        // statusOptions: [
        //   {
        //     text: '请选择状态',
        //     value: 0,
        //   },
        //   {
        //     text: '进行中',
        //     value: 1,
        //   },
        //   {
        //     text: '结束',
        //     value: 2,
        //   },
        // ],
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.queryForm.User_ID = userInfo.User_ID
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'userComplain',
        '城管-用户投诉列表',
        'zzdcg.yy.gov.cn/userComplain'
      )
    },
    methods: {
      //页面动态加载方法
      onLoad() {
        this.loading = true
        //当页面数大于总页数时，停止加载请求数据
        if (this.queryForm.intPageIndex >= this.pageCount) {
          this.loading = false
          this.isFinished = true //数据加载完
        } else {
          this.queryForm.intPageIndex++
          this.fetchData()
        }
      },
      //获取数据
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await GetUserComplainList(this.queryForm)
        console.log(data)
        Toast.clear()
        if (data.data.length > 0) {
          this.flag = true
          this.pageCount = data.pageCount
          this.list = this.list.concat(data.data)
          this.loading = false
        } else {
          this.flag = false
          this.loading = false //不处于加载状态
          this.isFinished = true //数据加载完，不会再处于加载状态
        }
      },

      //跳转至详情
      toInfo(item) {
        this.$router.push({
          path: 'userComplainInfo',
          query: {
            ID: Base64.encode(item.Det_ID),
            Isgrid: Base64.encode(item.Is_WGZ),
          },
        })
      },

      onSearch(val) {
        this.list = []
        this.queryForm.intPageIndex = 1
        //this.queryForm.QDDX_MC = val
        this.fetchData()
      },
      // onCancel() {
      //   this.list = []
      //   this.queryForm.intPageIndex = 1
      //   this.queryForm.QDDX_MC = ''
      //   this.fetchData()
      // },

      onRefresh() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.fetchData()
        this.isLoading = false
      },

      // onConfirm(date) {
      //   this.list = []
      //   this.queryForm.Date_OP = moment(date).format('YYYY-MM-DD')
      //   this.showPicker = false
      //   this.fetchData()
      // },

      // onConfirmStatus(value) {
      //   this.list = []
      //   this.value = value
      //   this.name = value.text
      //   this.queryForm.This_Status = value.value
      //   this.showStatus = false
      //   this.fetchData()
      // },

      //格式化时间
      // reset() {
      //   this.list = []
      //   this.queryForm.intPageIndex = 1
      //   this.queryForm.Date_OP = ''
      //   this.queryForm.This_Status = 0
      //   this.name = ''
      //   this.date = new Date()
      //   this.queryForm.QDDX_MC = ''
      //   this.fetchData()
      // },
    },
  }
</script>

<style scoped>
  .yun_newedition_resume_wantjob {
    padding-bottom: 0.266666rem;
    font-size: 0.426666rem;
    color: #666;
  }
  .yun_newedition_resume_wantjob_n {
    font-size: 0.426666rem;
    font-weight: bold;
    color: #000;
  }
  .yun_newedition_resumelist a {
    position: relative;
    display: block;
    padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  }
  .new_userlist_p {
    position: relative;
    padding-bottom: 0.266666rem;
    font-size: 0.346666rem;
    color: #333;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .yun_newedition_resume {
    padding: 0rem 0.32rem 0rem 0.32rem;
  }
  .yun_newedition_resumelist {
    margin-top: 0.266666rem;
    background: #fff;
    border-radius: 0.213333rem;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
    padding-left: 0.666666rem;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../assets/images/workBench/advise/icon.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
</style>
