import request from '@/util/request'

export function GetUserComplainList(params) {
  return request({
    url: '/UserComplain.asmx/GetUserComplainList',
    method: 'GET',
    params,
  })
}

export function GetUserComplainInfo(params) {
  return request({
    url: '/UserComplain.asmx/GetUserComplainInfo',
    method: 'GET',
    params,
  })
}

export function UserComplainCellBack(params) {
  return request({
    url: '/UserComplain.asmx/UserComplainCellBack',
    method: 'GET',
    params,
  })
}

export function UserComplainCellRelay(params) {
  return request({
    url: '/UserComplain.asmx/UserComplainCellRelay',
    method: 'GET',
    params,
  })
}

export function GetUserComplainApparitorList(params) {
  return request({
    url: '/UserComplain.asmx/GetUserComplainApparitorList',
    method: 'GET',
    params,
  })
}

export function UserComplainApparitor(params) {
  return request({
    url: '/UserComplain.asmx/UserComplainApparitor',
    method: 'GET',
    params,
  })
}
